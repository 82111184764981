export const RHMPEProductDetailsContainer = () => import('./rhm-pe-product-details-container');
export const RHMPEProductManuals = () => import('./rhm-pe-product-manuals');
export const RHMPEProductManualsContainer = () => import('./rhm-pe-product-manuals-container');
export const RHMPEProductLongDescription = () => import('./rhm-pe-product-long-description');
export const RHMPEProductImageViewer = () => import('./rhm-pe-product-image-viewer');
export const RHMPEProductOfferCard = () => import('./rhm-pe-product-offer-card');
export const RHMPEProductOfferCardsContainer = () => import('./rhm-pe-product-offer-cards-container');
export const RHMPEProductSpecs = () => import('./rhm-pe-product-specs');
export const RHMPEProductCode = () => import('./rhm-pe-product-code');
export const RHMPEProductName = () => import('./rhm-pe-product-name');
export const RHMPEProductPrice = () => import('./rhm-pe-product-price');
export const RHMPEProductShortDescription = () => import('./rhm-pe-product-short-description');
export const RHMPEProductAddToCartButton = () => import('./rhm-pe-product-add-to-cart-button');
export const RHMPEProductLargeDimensions = () => import('./rhm-pe-product-large-dimensions');
export const RHMPEProductStamps = () => import('./rhm-pe-product-stamps');
export const RHMPEProductRecommendationsPDP = () => import("./rhm-pe-product-recommendations-pdp");
export const RHMPEDetailsBar = () => import('./rhm-pe-details-bar');
export const RHMPEProductShippingStamps = () => import('./rhm-pe-product-shipping-stamps');
export const RHMPEProductShippingDelivery = () => import('./rhm-pe-product-shipping-delivery');
export const RHMPEProductShippingContainer = () => import('./rhm-pe-product-shipping-container');
