export const createPaypalPayment = () => import('./createPaypalPayment');
export const createBillingAgreement = () => import('./createBillingAgreement');
export const listProfileSavedPayments = () => import('./listProfileSavedPayments');
export const setDefaultPaymentMethod = () => import('./setDefaultPaymentMethod');
export const rhmGetOrderList = () => import('./Rhm-getorderlist');
export const doAddressValidation = () => import('./validate-address')
export const _typeAhead = () => import('./type-ahead')
export const _subscribeCard = () => import('./subscribeCard')
export const fetchShippingMethodDetails = () => import('./fetch-shipping-method-details')
export const getTransbankTransactionStatus = () => import('./getTransbankTransactionStatus')
export const transbankCommitTransaction = () => import('./transbankCommitTransaction')
