export const RHMPEStripBanner = () => import('./rhm-pe-strip-banner');
export const RHMPEHeader = () => import('./rhm-pe-header');
export const RHMPELinkBar = () => import('./rhm-pe-link-bar');
export const RHMPEHeroBanner = () => import("./rhm-pe-hero-banner");
export const RHMPEBannerCarouselContainer = () => import("./rhm-pe-banner-carousel-container");
export const RHMPEFooter = () => import('./rhm-pe-footer');
export const RHMPECardsCarouselContainer = () => import("./rhm-pe-cards-carousel-container");
export const RHMPEImageCard = () => import("./rhm-pe-cards-carousel-container/widgets/rhm-pe-image-card");
export const RHMPEContainer = () => import("./rhm-pe-container");
export const RHMPEProductRecommendationsWithBanner = () => import("./rhm-pe-product-recommendations-carousel-with-banner");
export const RHMPEHeroBannersContainer = () => import("./rhm-pe-hero-banners-container");
