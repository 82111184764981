/* eslint-disable camelcase */
// RHM Search
export { RHMSearchBoxDesktop as _RHMB2BSearchBoxDesktop } from "../rhm-b2c/rhm-product-listing/rhm-search-box";
export { RHMSearchBoxMobile as _RHMB2BSearchBoxMobile } from "../rhm-b2c/rhm-product-listing/rhm-search-box";
const RHMSearchTermsSummary = () => import("../rhm-b2c/rhm-product-listing/rhm-search-terms-summary");
export { RHMSearchTermsSummary as _RHMB2BSearchTermsSummary };
const RHMSearchSerialModal = () => import("../rhm-b2c/rhm-product-listing/rhm-search-serial-modal");
export { RHMSearchSerialModal as _RHMB2BSearchSerialModal };

//RHM Collection
export const _RHMB2BDynamicProductList = () =>
  import("../rhm-b2c/rhm-product-listing/components/rhm-dynamic-product-list/component");
export const _RHMB2BFacetedNavigationDesktop = () =>
  import("../rhm-b2c/rhm-product-listing/components/rhm-faceted-navigation/desktop");
export const _rhmb2bproductlisting = () => import("../rhm-b2c/rhm-product-listing");

//RHM Dynamic Breadcrumbs
export { RHMDynamicBreadcrumbs as _RHMB2BDynamicBreadcrumbs } from "../rhm-b2c/rhm-product-listing/components/rhm-dynamic-breadcrumbs";

// Home Page Widget
export const _RHMFeaturedProducts = () => import("./rhm-featured-products/component");
export {RHMMenuMobile as _RHMB2BMenuMobile} from './rhm-common/rhm-menu-mobile';
//Hero banner 
const RHMB2BHeroBanner = () => import('./rhm-hero-banner');
export {RHMB2BHeroBanner as _RHMB2BHeroBanner};
const RHMB2BRuudBanner = () => import('./hero-banner/Rudd-hero-banner');
export {RHMB2BRuudBanner as _RHMB2BRuudBanner};
const RHMB2BSureComfortBanner = () => import('./hero-banner/sureComfort-hero-banner');
export {RHMB2BSureComfortBanner as _RHMB2BSureComfortBanner};
const RHMB2BTruelineBanner = () => import('./hero-banner/trueline-hero-banner');
export {RHMB2BTruelineBanner as _RHMB2BTruelineBanner};
//Hero Banner Image
const RheemB2BHalfWidthBanner = () => import('./rhm-half-width-banner/rheem-half-width-banner');
export {RheemB2BHalfWidthBanner as _RheemB2BHalfWidthBanner};
const SureComfortB2BHalfWidthBanner = () => import('./rhm-half-width-banner/sureComfort-half-width-banner');
export {SureComfortB2BHalfWidthBanner as _SureComfortB2BHalfWidthBanner};
const RuudB2BHalfWidthBanner = () => import('./rhm-half-width-banner/ruud-half-width-banner');
export {RuudB2BHalfWidthBanner as _RuudB2BHalfWidthBanner};
const TrueLineB2BHalfWidthBanner = () => import('./rhm-half-width-banner/trueline-half-width-banner');
export {TrueLineB2BHalfWidthBanner as _TrueLineB2BHalfWidthBanner};

//RHM Logo
export {RHMCompanyLogo as _RHMB2BCompanyLogo} from './rhm-common/rhm-company-logo';

// profile related widets
export { RHMProfileRegistration as _RHMB2BProfileRegistration } from './rhm-profile-registration';
export { RHMProfileResetPassword as _RHMB2BProfileResetPassword } from '../rhm-b2c/rhm-profile/rhm-profile-reset-password';
export { RHMLogin as _RHMB2BLogin } from "./rhm-login";
export { RHMUserProfileLinksDesktop as _RHMB2BUserProfileLinksDesktop } from "../rhm-b2c/rhm-session/rhm-user-profile-links";
export { RHMUserProfileLinksMobile as _RHMB2BUserProfileLinksMobile } from "../rhm-b2c/rhm-session/rhm-user-profile-links";
export {RHMB2BProfileDetails as _RHMB2BProfileDetails} from './profile/rhm-b2b-profile-details';
export {RHMProfileAccountContact as _RHMProfileAccountContact} from './profile/rhm-b2b-account-contacts';
export const _RHMAddOrEditAccountContact = () => import('./profile/_rhm-b2b-add-or-edit-account-contact');

// profile update password
export {RHMProfileUpdatePassword as _RHMB2BProfileUpdatePassword} from './profile/rhm-profile-update-password';
export {RHMAccountPicker as _RHMAccountPicker} from './profile/_rhm_b2b-account-picker';

//RHM Category
export {RHMCollectionNavigationDesktop as _RHMB2BCollectionNavigationDesktop} from './rhm-category/rhm-collection-navigation-desktop';
export {RHMCollectionNavigation as _RHMB2BCollectionNavigationMobile} from './rhm-category/rhm-collection-navigation';

// RHM Cart
export {RHMCartContainer as _RHMB2BCartContainer} from "./rhm-cart/rhm-cart-container";
export {RHMCartItemDetails as _RHMB2BCartItemDetails} from "./rhm-cart/rhm-cart-item-details";
export {RHMPromotionCodeEntry as _RHMB2BPromotionCodeEntry} from "./rhm-cart/rhm-promotion-code-entry";
export {RHMPromotionsDisplay as _RHMB2BPromotionsDisplay} from "./rhm-cart/rhm-promotions-display";


//RHM Checkout
export {RHMCheckoutOrderSummary as _RHMb2bCheckoutOrderSummary} from './rhm-checkout/rhm-checkout-order-summary';
export {RHMCheckoutCartSummary as _RHMB2BCheckoutCartSummary} from './rhm-checkout/rhm-checkout-cart-summary';
export {RHMCheckoutOrderConfirmationContainer as _RHMB2BCheckoutOrderConfirmationContainer} from './rhm-checkout/rhm-checkout-order-confirmation-container';
export {RHMCheckoutOrderConfirmation as _RHMB2BCheckoutOrderConfirmation} from './rhm-checkout/rhm-checkout-order-confirmation';
export {RHMCheckoutSingleShippingDetails as _RHMB2BCheckoutSingleShippingDetails} from './rhm-checkout/rhm-checkout-single-shipping-details';
export {RHMCheckoutShippingContainer as _RHMB2BCheckoutShippingContainer} from './rhm-checkout/rhm-checkout-shipping-container';
export {RHMB2BCheckoutResponsiveContainerDesktop as _RHMB2BCheckoutResponsiveContainerDesktop} from './rhm-checkout/rhm-checkout-responsive-container-desktop';
export {RHMB2BCheckoutResponsiveContainerMobile as _RHMB2BCheckoutResponsiveContainerMobile} from './rhm-checkout/rhm-checkout-responsive-container-mobile';
export {RHMB2bCheckoutPaymentsContainer as _RHMB2bCheckoutPaymentsContainer} from './rhm-checkout/rhm-checkout-payments-container';
export {RHMB2bCheckoutPaymentMethodsContainer as _RHMB2bCheckoutPaymentMethodsContainer} from './rhm-checkout/rhm-checkout-payment-methods-container';
export {RHMB2bCheckoutCreditCard as _RHMB2bCheckoutCreditCard} from './rhm-checkout/rhm-checkout-credit-card'
export {RHMB2BCheckoutInvoice as _RHMB2BCheckoutInvoice} from './rhm-checkout/rhm-checkout-invoice';

export {tlProfileRegistration as _TLProfileRegistration} from './tl-profile-registration';

//RHM Product Page
export const _RHMB2BProductDetailsContainer = () => import('./rhm-product');
export const _RHMB2BProduct = () => import('./rhm-product/components/rhm-product-details');
export const _RHMB2BProductPrice = () => import('./rhm-product/components/rhm-product-price/component');
export const _RHMB2BProductInventoryStatus = () => import('./rhm-product/components/rhm-product-inventory-status/component');


export {RHMProfilePurchaseLists as _RHMProfilePurchaseLists} from './profile/rhm-b2b-profile-purchase-lists';
export {RHMProfileCreatePurchaseList as _RHMProfileCreatePurchaseList} from './profile/rhm-b2b-create-purchase-list';
export {RHMProfilePurchaseListsSummary as _RHMProfilePurchaseListsSummary} from './profile/rhm-b2b-profile-purchase-lists-summary';
export {RHMProfilePurchaseListActionButtons as _RHMProfilePurchaseListActionButtons} from './profile/rhm-b2b-profile-purchase-list-action-buttons';
export {RHMProfilePurchaseListInformation as _RHMProfilePurchaseListInformation} from './profile/rhm-b2b-profile-purchase-list-information';
export {RHMProfilePurchaseListSummaryInformation as _RHMProfilePurchaseListSummaryInformation} from './profile/rhm-b2b-profile-purchase-list-summary-information';
export {RHMProfilePurchaseListDetailsContainer as _RHMProfilePurchaseListDetailsContainer} from './profile/rhm-b2b-profile-purchase-list-details-container';
export {RHMB2bProfileSavedCards as _RHMB2bProfileSavedCards} from './profile/rhm-b2b-profile-saved-cards';
export {RHMB2bProfileAddCreditCard as _RHMB2bProfileAddCreditCard} from './profile/rhm-b2b-profile-add-credit-card';
export {RHMB2bProfileUpdateExpiredPassword as _RHMB2bProfileUpdateExpiredPassword} from './profile/rhm-b2b-profile-update-expired-password';

export {ContactRegistrationRequests as _ContactRegistrationRequests} from './profile/_rhm-b2b-contact-registration-requests'
export {ContactRegistrationDetail as _ContactRegistrationDetail} from './profile/_rhm-b2b-contact-registration-detail'

const RhmB2B_OrderDetails = () => import('./order-details/component')
export {RhmB2B_OrderDetails as _RhmB2B_OrderDetails}

const RHMB2B_ProductComparisionPage = () => import('./rhm-product-comparison')
export {RHMB2B_ProductComparisionPage as _RHMB2B_ProductComparisionPage}

const RhmQuickOrder = () => import('./rhm-direct-item-entry');
export {RhmQuickOrder as _RhmQuickOrder};

const RHMB2bHeaderBumper = () => import('./rhm-header-bumper');
export {RHMB2bHeaderBumper as _RHMB2bHeaderBumper};

export {RHMProfileOrderHistory as _RHMB2BProfileOrderHistory} from './profile/rhm-profile-order-history';
// RHM B2B Footer
const RHM_B2B_Footer = () => import('./rhm-footer');
export {RHM_B2B_Footer as _RHM_B2B_Footer};

//RHM B2B Price Book
const RHMPriceBookDownload = () => import('./profile/_rhm-b2b-price-book');
export {RHMPriceBookDownload as _RHMB2bPriceBook}

export {RHMTableSearchBoxDesktop as _RHMTableSearchBoxDesktop} from './rhm-direct-item-entry/components/rhm-table-search-box';

const RHMSavedCart = () => import('./rhm-cart/rhm-b2b-saved-cart');
export {RHMSavedCart as _RHMSavedCart};

const RHMGtm = () => import('./gtm');
export {RHMGtm as _RHMGtm};