export const RHMCOProductDetailsContainer = () => import("./rhm-co-product-details-container");
export const RHMCOProductManuals = () => import("./rhm-co-product-manuals");
export const RHMCOProductManualsContainer = () => import("./rhm-co-product-manuals-container");
export const RHMCOProductLongDescription = () => import("./rhm-co-product-long-description");
export const RHMCOProductOfferCard = () => import("./rhm-co-product-offer-card");
export const RHMCOProductOfferCardsContainer = () => import("./rhm-co-product-offer-cards-container");
export const RHMCOProductSpecs = () => import("./rhm-co-product-specs");
export const RHMCOProductImageViewer = () => import("./rhm-co-product-image-viewer");
export const RHMCOProductCode = () => import('./rhm-co-product-code');
export const RHMCOProductName = () => import('./rhm-co-product-name');
export const RHMCOProductPrice = () => import('./rhm-co-product-price');
export const RHMCOProductShortDescription = () => import('./rhm-co-product-short-description');
export const RHMCOProductAddToCartButton = () => import('./rhm-co-product-add-to-cart-button');
export const RHMCOProductLargeDimensions = () => import('./rhm-co-product-large-dimensions');
export const RHMCOProductStamps = () => import('./rhm-co-product-stamps');
export const RHMCOProductRecommendationsPDP = () => import('./rhm-co-product-recommendations-pdp');
export const RHMCODetailsBar = () => import('./rhm-co-details-bar');
export const RHMCOProductShippingDelivery = () => import('./rhm-co-product-shipping-delivery');
export const RHMCOProductShippingContainer = () => import('./rhm-co-product-shipping-container')
export const RHMCOProductExtaInfos = () => import('./rhm-co-product-exta-infos');
export const RHMCOProductNotifyMe = () => import('./rhm-co-product-details-notify-me');
