export const RHMBRProductLongDescription = () => import('./rhm-br-product-long-description');
export const RHMBRProductDetailsContainer = () => import('./rhm-br-product-details-container');
export const RHMBRProductSpecs = () => import('./rhm-br-product-specs');
export const RHMBRProductImageViewer = () => import('./rhm-br-product-image-viewer');
export const RHMBRProductName = () => import('./rhm-br-product-name');
export const RHMBRProductCode = () => import('./rhm-br-product-code');
export const RHMBRProductPrice = () => import('./rhm-br-product-price');
export const RHMBRProductVariantOptions = () => import('./rhm-br-product-variant-options');
export const RHMBRProductInventoryStatus = () => import('./rhm-br-product-inventory-status');
export const RHMBRProductAddToCartButton = () => import('./rhm-br-product-add-to-cart-button');
export const RHMBRProductLargeDimensions = () => import('./rhm-br-product-large-dimensions');
export const RHMBRProductManuals = () => import('./rhm-br-product-manuals');
