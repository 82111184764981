import { fromPairs } from 'lodash-es';

// RHM Chile Common
export { RHMChileLogo as _RHMChileLogo } from './rhm-chile-common/rhm-chile-logo';
export { RHMChileWebPay as _RHMChileWebPay } from './rhm-chile-common/rhm-chile-web-pay';
export { RHMChileSearchBoxMobile as _RHMChileSearchBoxMobile } from './rhm-chile-common/rhm-chile-searchbox';
export { RHMChileSearchBoxDesktop as _RHMChileSearchBoxDesktop } from './rhm-chile-common/rhm-chile-searchbox';
export { RHMChileMiniCart as _RHMChileMiniCart } from './rhm-chile-common/rhm-chile-minicart';
export {RHMChileNavigationBreadcrumbs as _RHMChileNavigationBreadcrumbs} from './rhm-chile-navigation-breadcrumbs';
export { RHMSplendidSearchButtonForMobile as _RHMSplendidSearchButtonForMobile } from './rhm-chile-common/rhm-chile-searchbox';

//RHM Chile Login
export {RHMChileLogin as _RHMChileLogin} from './rhm-chile-session/rhm-chile-login';

//RHM 404
export {RHMChileFindPerformance as _RHMChileFindPerformance} from './rhm-chile-find-performance';

// RHM Header
export { RHMChileDesktopHeaderContainer as _RHMChileDesktopHeaderContainer } from './rhm-chile-common/rhm-chile-header-container';
export { RHMChileMobileHeaderContainer as _RHMChileMobileHeaderContainer } from './rhm-chile-common/rhm-chile-header-container';
export { RHMChileDesktopHeaderTopbar as _RHMChileDesktopHeaderTopbar } from './rhm-chile-common/rhm-chile-desktop-header-topbar';
export { RHMChileMobileHeaderTopbar as _RHMChileMobileHeaderTopbar } from './rhm-chile-common/rhm-chile-mobile-header-topbar';
export { RHMChileDesktopHeaderSubContainer as _RHMChileDesktopHeaderSubContainer } from './rhm-chile-common/rhm-chile-header-container';

export { RHMChileDesktopHeaderNavbar as _RHMChileDesktopHeaderNavbar } from './rhm-chile-common/rhm-chile-desktop-header-navbar';
export { RHMChileMobileHeaderNavbar as _RHMChileMobileHeaderNavbar } from './rhm-chile-common/rhm-chile-mobile-header-navbar';

export { RHMChileDesktopHeaderMenubar as _RHMChileDesktopHeaderMenubar } from './rhm-chile-common/rhm-chile-desktop-header-menubar';
export { RHMChileMobileHeaderMenubar as _RHMChileMobileHeaderMenubar } from './rhm-chile-common/rhm-chile-mobile-header-menubar';

//RHM Home
export {RHMChileWhatAreLookingFor as _RHMChileWhatAreLookingFor} from './rhm-chile-what-are-looking-for';
export {RHMChileFeaturedProducts as _RHMChileFeaturedProducts} from './rhm-chile-featured-products';
export {RHMChileCarouselBanner as _RHMChileCarouselBanner} from './rhm-chile-carousel-banner';
export {RHMChileShippingPriceText as _RHMChileShippingPriceText} from './rhm-chile-shipping-price-text';


//RHM Collection
// export const _RHMChileDynamicProductList = () =>import('./rhm-chile-product-listing/components/rhm-chile-dynamic-product-list/component');
export const _RHMChileFacetedNavigationDesktop = () =>
  import('./rhm-chile-product-listing/components/rhm-chile-faceted-navigation/desktop');
export const _RHMChileproductlisting = () => import('./rhm-chile-product-listing');
export {RHMChileDynamicProductList as _RHMChileB2CDynamicProductList} from './rhm-chile-product-listing/components/rhm-chile-dynamic-product-list'

// const RHMChileDynamicProductList = () => import('./rhm-chile-product-listing/components/rhm-chile-dynamic-product-list/component')
// export {RHMChileDynamicProductList as _RHMChileDynamicProductList}

export {RHMChileDynamicBreadcrumbs as _RHMChileDynamicBreadcrumbs} from './rhm-chile-product-listing/components/rhm-chile-dynamic-breadcrumbs';
export {RHMChilePLPAutumnalCarousel as _RHMChilePLPAutumnalCarousel} from './rhm-chile-product-listing/rhm-chile-plp-autumnal-carousel';
export {RHMChilePLPCategoryBanner as _RHMChilePLPCategoryBanner} from './rhm-chile-product-listing/rhm-chile-plp-category-banner';
export {RHMChileStaticPLPBanner as _RHMChileStaticPLPBanner} from './rhm-chile-product-listing/rhm-chile-static-plp-banner';
export {RHMChilePLPPurchaseCarousel as _RHMChilePLPPurchaseCarousel} from './rhm-chile-product-listing/components/rhm-chile-plp-purchase-carousel';
export {RHMChilePLPCategoryTopFilter as _RHMChilePLPCategoryTopFilter} from './rhm-chile-product-listing/components/rhm-chile-plp-category-top-filter';
export {RHMChileCategoryContentContainer as _RHMChileCategoryContentContainer} from './rhm-chile-product-listing/components/rhm-chile-category-content-container'

// RHM CART
export {RHMChileCartContainer as _RHMChileCartContainer} from './rhm-chile-cart/rhm-chile-cart-container';
export {RHMChileCartItemDetails as _RHMChileCartItemDetails} from './rhm-chile-cart/rhm-chile-cart-item-details';
export {RHMChileCartOrderSummary as _RHMChileCartOrderSummary} from './rhm-chile-cart/rhm-chile-cart-order-summary';
export {RHMChilePromotionCodeEntry as _RHMChilePromotionCodeEntry} from './rhm-chile-cart/rhm-chile-promotion-code-entry';
export {RHMChilePromotionsDisplay as _RHMChilePromotionsDisplay} from './rhm-chile-cart/rhm-chile-promotions-display';
export {RHMChileCartShippingEstimate as _RHMChileCartShippingEstimate} from './rhm-chile-cart/rhm-chile-cart-shipping-estimate';
export {RHMChileMiniCartDesktop as _RHMChileMiniCartDesktop} from './rhm-chile-cart/rhm-chile-mini-cart';
export {RHMChileMiniCartMobile as _RHMChileMiniCartMobile} from './rhm-chile-cart/rhm-chile-mini-cart';

//RHM Checkout
export {RHMChileCheckoutContainerDesktop as _RHMChileCheckoutContainerDesktop} from './rhm-chile-checkout/rhm-chile-checkout-container-desktop';
export {RHMChileCheckoutContainerMobile as _RHMChileCheckoutContainerMobile} from './rhm-chile-checkout/rhm-chile-checkout-container-mobile';
//export {RHMChileCheckoutCartSummary as _RHMChileCheckoutCartSummary} from './rhm-chile-checkout/rhm-chile-checkout-cart-summary';
const RHMChileCheckoutCartSummary = () => import('./rhm-chile-checkout/rhm-chile-checkout-cart-summary');
export {RHMChileCheckoutCartSummary as _RHMChileCheckoutCartSummary};
export {RHMChileCheckoutLogin as _RHMChileCheckoutLogin} from './rhm-chile-checkout/rhm-chile-checkout-login';
export {RHMChileCheckoutOrderSummary as _RHMChileCheckoutOrderSummary} from './rhm-chile-checkout/rhm-chile-checkout-order-summary';
export {RHMChileCheckoutShippingContainer as _RHMChileCheckoutShippingContainer} from './rhm-chile-checkout/rhm-chile-checkout-shipping-container';
export {RHMChileCheckoutSingleShippingDetails as _RHMChileCheckoutSingleShippingDetails} from './rhm-chile-checkout/rhm-chile-checkout-single-shipping-details';
export {RHMChileCheckoutOrderConfirmation as _RHMChileCheckoutOrderConfirmation} from './rhm-chile-checkout/rhm-chile-checkout-order-confirmation';
export {RHMChileCheckoutOrderConfirmationContainer as _RHMChileCheckoutOrderConfirmationContainer} from './rhm-chile-checkout/rhm-chile-checkout-order-confirmation-container';
export {RHMChileCheckoutPaymentMethodsContainer as _RHMChileCheckoutPaymentMethodsContainer} from './rhm-chile-checkout/rhm-chile-checkout-payment-methods-container';
export {RHMChileCheckoutPaymentsContainer as _RHMChileCheckoutPaymentsContainer} from './rhm-chile-checkout/rhm-chile-checkout-payments-container';
// export {RHMChileCheckoutCreditCard as _RHMChileCheckoutCreditCard} from './rhm-chile-checkout/rhm-chile-checkout-credit-card';

export {RHMChileCheckoutWebpay as _RHMChileCheckoutWebpay} from './rhm-chile-checkout/rhm-chile-checkout-webpay';





// RHM Footer
export { RHMChileDesktopFooterContainer as _RHMChileDesktopFooterContainer } from './rhm-chile-common/rhm-chile-footer-container';
export { RHMChileMobileFooterContainer as _RHMChileMobileFooterContainer } from './rhm-chile-common/rhm-chile-footer-container';
export { RHMChileFooterMenu as _RHMChileFooterMenu } from './rhm-chile-common/rhm-chile-footer-menu';
export { RHMChileFooterBottomLinks as _RHMChileFooterBottomLinks } from './rhm-chile-common/rhm-chile-footer-bottom-links';

//RHM Home
export {RHMChileAutumnalCarousel as _RHMChileAutumnalCarousel} from "./rhm-chile-autumnal-carousel"
export {RHMChileMiniPromotionalBanners2 as _RHMChileMiniPromotionalBanners2} from "./rhm-chile-mini-promotional-banners-2"

export {RHMChileInstaladores as _RHMChileInstaladores} from "./rhm-chile-common/rhm-chile-instaladores"

export {RHMChileFollowus as _RHMChileFollowus} from "./rhm-chile-common/rhm-chile-followus"

export {RHMChileRheemquality as _RHMChileRheemquality} from "./rhm-chile-common/rhm-chile-rheemquality"

export {RHMChileNinetyYearsPlus as _RHMChileNinetyYearsPlus} from "./rhm-chile-common/rhm-chile-NinetyYearsPlus"

export {RHMChileSustainability as _RHMChileSustainability} from "./rhm-chile-common/rhm-chile-sustainability"


// RHM Simulador
export {RHMChileSimuladorBanner as _RHMChileSimuladorBanner} from './rhm-chile-find-your-product/rhm-chile-simulador-banner'
export const RHMMiniBannersContainer = () => import('./rhm-mini-banners-container');

//RHM FAQ
export {RHMChileFAQPage as _RHMChileFAQPage} from "./rhm-chile-common/rhm-chile-FAQ-page";

export {RHMCartItemCommon as _RHMCartItemCommon} from './common/cart-item-common'

// export {RHMGuestAddAddress as _RHMGuestAddAddress} from './rhm-chile-checkout-page/guest-checkout-address'

// pdp
export {RHMProductDetailsContainer as _RHMChileProductDetailsContainer}  from './rhm-chile-product';
export {RHMProduct as _RHMChileProduct} from './rhm-chile-product/components/rhm-product-details';
export {RHMProductPrice as _RHMChileProductPrice} from './rhm-chile-product/components/rhm-product-price';
export {RHMProductInventoryStatus as _RHMChileProductInventoryStatus} from './rhm-chile-product/components/rhm-product-inventory-status';

// RHM Profile
export {RHMChileProfileResetPassword as _RHMChileProfileResetPassword} from "./rhm-chile-profile/rhm-chile-profile-reset-password";
export {RHMChileProfileOrderInformation as _RHMChileProfileOrderInformation} from "./rhm-chile-profile/rhm-chile-profile-order-information";
export {RHMChileProfileOrderSummary as _RHMChileProfileOrderSummary} from "./rhm-chile-profile/rhm-chile-profile-order-summary";
export {RHMChileShippingInformation as _RHMChileShippingInformation} from "./rhm-chile-profile/rhm-chile-shipping-information";
export {RHMChileProfileOrderActions as _RHMChileProfileOrderActions} from "./rhm-chile-profile/rhm-chile-profile-order-actions";
export {RHMChilePaymentInformation as _RHMChilePaymentInformation} from "./rhm-chile-profile/rhm-chile-payment-information";
export {RHMChileProfileOrderDetailsContainer as _RHMChileProfileOrderDetailsContainer} from "./rhm-chile-profile/rhm-chile-profile-order-details-container"
export {RHMChileProfileAddressBook as _RHMChileProfileAddressBook} from "./rhm-chile-profile/rhm-chile-profile-address-book"
export {RHMChileProfileUpdatePassword as _RHMChileProfileUpdatePassword} from "./rhm-chile-profile/rhm-chile-profile-update-password";
export {RHMChileProfileDetailsSummary as _RHMChileProfileDetailsSummary} from "./rhm-chile-profile/rhm-chile-profile-details-summary";
export {RHMChileProfileOrderHistory as _RHMChileProfileOrderHistory} from './rhm-chile-profile/rhm-chile-profile-order-history';
export {RHMChileProfileAddressForm as _RHMChileProfileAddressForm} from './rhm-chile-profile/rhm-chile-profile-address-form';
export {ProfileUpdateExpiredPassword as _ChileProfileUpdateExpiredPassword} from './rhm-chile-profile/profile-update-expired-password';

//feature-article-page-inmobiliarias
export {RHMChileArticlePageInmobiliarias as _RHMChileArticlePageInmobiliarias} from './rhm-chile-article-pages/page-inmobiliarias/rhm-chile-article-page-inmobiliarias';

// home-carousel-banner-container
// export {RhmChileCarouselBannerContainer as _RhmChileCarouselBannerComponent} from "./rhm-chile-carousel-banner-container"
export const RHMChileCarouselBannerWrapper = () => import("./rhm-chile-carousel-banner-wrapper")
export {RHMChileCarouselBannerWrapper as _RHMChileCarouselBannerWrapper}

//Asistencia total Rheem
export {RHMChileArticleComprometidosText as _RHMChileArticleComprometidosText} from './rhm_chile_Total_Assistance/rhm-chile-article-comprometidos-text';
export {RHMChileAsistenciaTotalRheem as _RHMChileAsistenciaTotalRheem} from './rhm_chile_Total_Assistance/rhm-chile-asistencia-total-rheem';
export {RHMChileArticleCallcenter as _RHMChileArticleCallcenter} from './rhm_chile_Total_Assistance/rhm-chile-callcenter';
export {RHMChileArticleWebsite as _RHMChileArticleWebsite} from './rhm_chile_Total_Assistance/rhm-chile-article-website';
export {RHMChileArticleHorarioText as _RHMChileArticleHorarioText} from './rhm_chile_Total_Assistance/rhm-chile-article-horario-text'; 
export {RHMChileArticleTimeSchedule as _RHMChileArticleTimeSchedule} from './rhm_chile_Total_Assistance/rhm-chile-article-time-schedule'; 
export {RHMChileArticleFormularo as _RHMChileArticleFormularo} from './rhm_chile_Total_Assistance/rhm-chile-article-formularo';
export {RHMChileArticleSeDebeTextline as _RHMChileArticleSeDebeTextline} from './rhm_chile_Total_Assistance/rhm-chile-article-se-debe-textline';

 //ATR Full Assist Rheem
 export {RHMChileATRFull as _RHMChileATRFull} from './rhm-chile-common/rhm-chile-ATR-Full';
 //Testing
 export {SplendidArticlePage as _SplendidArticlePage} from './rhm-chile-common/splendid-article-page';

// Home Carousel Banner rhmchilecarouselbanner2
export const rhmchilecarouselbanner2 = () => import('./rhm-chile-carousel-banner-2');
export {rhmchilecarouselbanner2 as _rhmchilecarouselbanner2};

export {RHMChileCarouselBanner3 as _RHMChileCarouselBanner3} from "./rhm-chile-carousel-banner-3"

// PromotionalBanner-5 and PromotionalBanner-6
// export {RHMChilePromotionalBannerContainer as _RHMChilePromotionalBannerComponent} from "./rhm-chile-promotional-banner-container"

export const RHMChileDynamicSiteBasedBanner = () => import("./rhm-chile-dynamic-site-based-banner");

//Terms condtions
export {RHMChiletermsconditions as _RHMChiletermsconditions} from './rhm-chile-common/rhm-chile-terms-conditions';

//feature-article-page-contact-us
export {RHMChileArticlePageContactUs as _RHMChileArticlePageContactUs} from "./rhm-chile-article-pages/page-contactUs";

// Autumnal Carousel 
export { RHMChileAutumnalCarouselComponent as _RHMChileAutumnalCarouselComponent} from './rhm-chile-autumnal-carousel-component'


//Frequent questions
export{ RHMChilefrequentquestions as _RHMChilefrequentquestions} from './rhm-chile-common/rhm-chile-frequent-questions';

//policiesofguarantees
export {RHMChilepoliciesofguarantees as _RHMChilepoliciesofguarantees} from './rhm-chile-common/rhm-chile-policies-of-guarantees';

//cookies-policies
export {RHMChileCookiesPolicies as _RHMChileCookiesPolicies} from './rhm-chile-common/rhm-chile-cookies-policies';

//Privacy-policies 
export {RHMChilePrivacyPolicies as _RHMChilePrivacyPolicies} from './rhm-chile-common/rhm-chile-privacy-policies';

export {RHMChileArticlePageInnovaciones as _RHMChileArticlePageInnovaciones} from './rhm-chile-article-pages/page-innovaciones';
export {RHMChileArticlePageTechnologia as _RHMChileArticlePageTechnologia} from './rhm-chile-article-pages/page-technologia';
export {RHMChileCheckoutContinueToReviewOrderButton as _RHMChileCheckoutContinueToReviewOrderButton} from './rhm-chile-checkout/checkout-continue-to-review-order-button'
export {RHMChileDispatchTerms as _RHMChileDispatchTerms} from './rhm-chile-common/rhm-chile-dispatch-terms';
export {RHMChileReturnExchangeProduct as _RHMChileReturnExchangeProduct} from './rhm-chile-common/rhm-chile-return-exchange-product';

// RHMChileInstalodresPage

export const RHMChileInstalodresContent = () => import('./rhm-chile-instalodres-content');
export {RHMChileInstalodresContent as _RHMChileInstalodresContent};

export const RHMChileInstalodresPromotionalBanner3 = () => import('./rhm-chile-instalodres-promotional-banner-3');
export {RHMChileInstalodresPromotionalBanner3 as _RHMChileInstalodresPromotionalBanner3};

export const RHMChileInstalodresPromotionalBanner12 = () => import('./rhm-chile-instalodres-promotional-banner-12');
export {RHMChileInstalodresPromotionalBanner12 as _RHMChileInstalodresPromotionalBanner12};

export const RHMChileInstalodresPromotionalBanner45 = () => import('./rhm-chile-instalodres-promotional-banner-45');
export {RHMChileInstalodresPromotionalBanner45 as _RHMChileInstalodresPromotionalBanner45};

export const RHMChileInstalodresUpcomingTimings = () => import('./rhm-chile-instalodres-upcoming-timings');
export {RHMChileInstalodresUpcomingTimings as _RHMChileInstalodresUpcomingTimings};

export {RHMChilePageCommercialAirConditioning as _RHMChilePageCommercialAirConditioning} from './rhm-chile-article-pages/page-commercial-air-conditioning-light-commercial';

//Comparsion Extended

export {RHMChileCompareExtent as _RHMChileCompareExtent} from './rhm-chile-comparsion-extended';

// progress bars
export {RHMChileCartPageBar as _RHMChileCartPageBar} from './common/cart-progress-bar/cart-page-bar';
export {RHMChileShippingPageBar as _RHMChileShippingPageBar} from './common/cart-progress-bar/shipping-page-bar'
export {RHMChileCheckoutPageBar as _RHMChileCheckoutPageBar} from './common/cart-progress-bar/checkout-page-bar'


export {SplendidCorpMemorias as _SplendidCorpMemorias} from './splendid_article_pages/splendid_corp_memorias';








export {SplendidAtsArticle as _SplendidAtsArticle} from './splendid_article_pages/splendid_ats_article';

export {SplendidArticleHistoria as _SplendidArticleHistoria} from './splendid_article_pages/splendid_article_historia'

export {SplendidNovedadesArticle as _SplendidNovedadesArticle} from './splendid_article_pages/splendid_novedades_article';

export {SplendidCorporativoCem as _SplendidCorporativoCem} from './splendid_article_pages/splendid_corporativo_cem';

export {SplendidServiceArticle as _SplendidServiceArticle} from './splendid_article_pages/splendid_service_article';


export {SplendidCombDevDesp as _SplendidCombDevDesp} from './splendid_article_pages/splendid_comb_dev_desp';

export {SplendidCorpestadosfinan as _SplendidCorpestadosfinan} from './splendid_article_pages/splendid_corp_estados_finan';

//Artical pages
export {SplendidCorpInfoDeInteres as _SplendidCorpInfoDeInteres} from './splendid_article_pages/splendid_corp_info_de_interes';
export {SplendidCorpPdeCalidad as _SplendidCorpPdeCalidad} from './splendid_article_pages/splendid_corp_pde_calidad';

export {SplendidCorpComunicados as _SplendidCorpComunicados} from './splendid_article_pages/splendid_corp_comunicados';
export {SplendidCorpInformInstitu as _SplendidCorpInformInstitu} from './splendid_article_pages/splendid_corp_inform_institu';

export {Splendidpdegarantia as _Splendidpdegarantia} from './splendid_article_pages/splendid_corp_pdegarantia';

export {SplendidCertificado as _SplendidCertificado} from './splendid_article_pages/splendid_corp_certificado';

export {SplendidInfoDeGarantia as _SplendidInfoDeGarantia} from './splendid_article_pages/splendid_info_de_garantia';