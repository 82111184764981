export const RHMBRInfoCardsContainer = () => import('./rhm-br-info-cards-container');
export const RHMBRInfoCard = () => import('./rhm-br-info-card');
export const RHMBRFooter = () => import('./rhm-br-footer');
export const RHMBRHeader = () => import('./rhm-br-header');
export const RHMBRBannerContainer = () => import('./rhm-br-banner-container');
export const RHMBRBannerCarouselContainer = () => import('./rhm-br-banner-carousel-container');
export const RHMBRHeroBanner = () => import('./rhm-br-hero-banner');
export const RHMBRBannerImagesContainer = () => import('./rhm-br-banner-images-container');
export const RHMBRBannerImage = () => import('./rhm-br-banner-image');
export const RHMBRProductRecommendations = () => import('./rhm-br-product-recommendations-carousel');
export const RHMBRContainer = () => import('./rhm-br-container');
